import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './Accordian.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl, currentAuthor } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Accordion>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <FormattedMessage id="ListingPage.detailsTitle" />{' '}
              <span className="accordion__icon"></span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={css.details}>
              {existingListingFields.map(detail => (
                <li key={detail.key} className={css.detailsRow}>
                  <span className={css.detailLabel}>{detail.label}</span>
                  <span>{detail.value}</span>
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Shipping and Returns <span className="accordion__icon"></span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Individual sellers have the ability to create their own return policy for their
              listings by going to Profile {'>'} Your Shop {'>'} Return Policy. If the shop does not
              note a return policy timeframe, the return will be based on The Backstage Closet’s
              general return policy. To be eligible for a return, we ask that you email The
              Backstage Closet support within 5 days of receiving your order or click the "Return"
              button on your order summary.
            </p>
            <br />
            <p>
              <strong>RETURNS AS A BUYER:</strong> Returns can be requested by going to Inbox {'>'}{' '}
              As a Customer {'>'} Return. You can receive a full refund if an item wasn’t accurately
              described, is incorrect size, arrives damaged, or is never shipped. We don’t offer
              returns or refunds for wear, stains, or other flaws that are mentioned or pictured in
              the listing. Before buying, be sure to read the description closely, look over the
              photos, and ask questions if you have any!
            </p>
            <br />
            <p>
              <strong>RETURNS AS A SELLER:</strong> You can set your return policy by selecting
              Profile {'>'} Your Shop {'>'} Return Policy. Here you can set your own Return Policy
              which shows the amount of days your shop accepts returns. When a customer asks for a
              return you will be notified and if it falls into your return policy timeframe, you can
              accept this return.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </section>
  ) : null;
};

export default SectionDetailsMaybe;
